/* assets/styles/app.css */
html {
}

body {
    background-color: #000;
}

p, div {
    font-size: 16px;
    font-family: work-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6, .title {
    font-family: brothers, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: rgb(174,14,22);
}

h2 {
    font-size: 35px;
    line-height: 40px;
}

/** Menu */

#general-navigation {
    color: #FFF;
    display: grid;
    padding: 5px;
}

#general-navigation a:link, #general-navigation a:visited {
    color: #FFF;
    text-decoration: none;
    font-family: brothers, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    text-transform: uppercase;
}

#general-navigation a:hover{
    color: rgb(174,14,22);
    transition-duration: 0.5s;
    text-decoration: none;
}

.social-network {
    vertical-align: bottom;
    display: inline-block;
    width: 30%;
}

.social-network img {
    width: 45px;
    height: auto;
}

/** Canvas */

.content-canvas {
    background-color: #FFF;
    padding: 10px 10px 10px 10px;
}

/** Footer */

#footer {
    padding: 10px;
    color: #FFF;
}

#footer-links {
    text-align: center;
}

#footer a:link, #footer a:visited {
    color: #FFF;
    font-weight: bold;
    line-height: 35px;
    text-decoration: none;
}

#footer-links p {
    margin-top: 20px;
}

#footer-links li a:hover {
    color: rgb(174,14,22);
    transition-duration: 0.5s;
}

#ef-copyright {
    text-align: center;
}

#ef-copyright a:link, #ef-copyright a:visited {
    font-weight: bold;
    color: #009999;
    text-align: center;
}

#ef-copyright .dropdown button, #ef-copyright .dropdown .dropdown-menu {
    background-color: black;
    border: white solid thin;
}

#ef-copyright .dropdown-menu a:link, #ef-copyright .dropdown-menu a:visited {
    font-weight: bold;
    color: rgb(174,14,22);
    text-align: center;
}

/** Home */

.edito-grid {
    border-bottom: #000 2px solid;
    padding-bottom: 20px;
    margin-bottom: 10px;
}

.edito-grid img {
    width: 90%;
}

.edito strong {
    text-transform: uppercase;
}

.signature {
    text-align: right;
    font-weight: bold;
}

.item-grid {
    display: grid;
    column-gap: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.item-grid img {
    width: 100%;
}

.widescreen {
    display:block;
    aspect-ratio: 16/9;
    background-color: #000;
}

.item {
    position: relative;
    margin-bottom: 20px;

    & .squared {
        display:block;
        aspect-ratio:1;
        background-color: #000;
    }
}

.item a:link, .item a:visited {
    display:block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: white;
}

.item a:link .title, .item a:visited .title {
    color: #FFF;
    position: absolute;
    padding: 12px;
    bottom: 0px;
    left: 0px;
    display:block;
    width: 100%;
    font-size: 24px;
    text-shadow: 1px 1px 2px rgb(174,14,22);
    line-height: 26px;
}

.item a:link .title-movie, .item a:link .title-program, .item a:visited .title-movie, .item a:visited .title-program {
    padding: 12px 12px 75px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.item a:hover {
    background-color: rgba(220, 220, 220, 0.8);
}

.item a:hover, .item a:hover .title {
    color: rgb(174,14,22);
    transition-duration: 0.5s;
    text-decoration: none;
    text-shadow: none;
    text-overflow: none;
    overflow: auto;
    white-space: normal;
}

.restriction {
    position: absolute;
    width: 7.5%!important;
    top: 12px;
    right: 12px;
}

.director {
    font-size: 17px;
    text-transform: none;
}

.title-movie, .title-program {
    text-align:left;
}

.schedules_list {
    background-color: black;
    height: 65px;
    z-index: 99;
    color: white;
    position: relative;
    display: block;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.no-space {
    margin: 0px!important;
    padding: 0px!important;
}

.schedule_list > div {
    height: 100%!important;
}

.schedule_list div.row {
    margin: 0px!important;
    padding: 0px!important;
}

.schedule_dt {
    padding: 0px;
    margin: 0px;
    height: 100%;
}

.schedule_dt div {
    margin: 0px!important;
    padding: 0px!important;
}

.schedule_dt .row div:first-of-type {
    text-align: right;
}

.schedule_dt .row div:nth-child(2n) {
    text-align: center;
}

.schedules_list a:link, .schedules_list a:visited {
    position: relative!important;
    font-size: 16px;
    padding: 8px 0px 8px 0px;
    width: 100%;
    font-weight: bold;
    text-decoration: none;
}

.schedules_list .schedule_dt:not(:first-of-type) {
    border-left: #FFF 2px solid;
}

.pos_link {
    padding-top: 2px;
    width: 45px!important;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.program_details_title {
    font-size: 34px!important;
}

.program_content_div:not(:first-of-type) {
    border-top: #888 3px solid!important;
    padding-top: 20px;
    padding-bottom: 20px;
}

.program_movie_crew h4 {
    margin-top: 10px;
    font-size: 20px!important;
}

.detail-partners {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 5px;
}

.detail-partners img {
    max-height: 50px!important;
    max-width: 100px!important;
}

.details_movie_basic {
    font-weight: bold;
}

.details_movie_basic li {
    display: inline;
}

.details_movie_basic li:not(:first-of-type):before {
    content: ' - ';
}

.movie_details_comment, .program_details_comment {
    margin-top: 20px;
    padding-top: 20px;
    border-top: black 2px solid;
}

.movie_details_comment blockquote p {
    font-style: italic!important;
}

.movie_details_extra {
    padding-top: 20px;
    font-style: italic;
}

.director_detail {
    text-transform: none;
}

.content_details_title {
    font-size: 45px;
    word-break: break-word;
}

.original_title {
    font-size: 25px;
}

.director_detail {
    color: #000;
}

.details_main_picture {
    position: relative;
}

.details_main_picture img {
    width: 100%;
}

.details_screenings {
    padding: 20px 0px 20px 0px;
    margin: 20px 0px 20px 0px;
    border-top: black 3px solid;
    border-bottom: black 3px solid;
}

.details_screenings p:last-of-type {
    margin-bottom: 0px;
}

.information-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: black 3px solid;
    border-bottom: black 3px solid;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.practical_information ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0px;
}

.practical_information ul li {
    font-size: 14px;
    list-style-type: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: #999999 2px solid;
}

.practical_information ul li:first-of-type {
    margin-top: 0px;
    border-top: none;
}

.schedule_days {
    background-color: black;
    width: 100%;
    max-width: 100%;
    height: 100px;
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0px;
    margin: 0px !important;
    white-space: nowrap;
}

/* Works on Chrome, Edge, and Safari */
.schedule_days::-webkit-scrollbar {
    height: 8px;
}

.schedule_days::-webkit-scrollbar-track {
    background: rgba(88,88,88,0.4);
}

.schedule_days:hover::-webkit-scrollbar-track {
    background: rgba(120,120,120, 0.9);
}

.schedule_days::-webkit-scrollbar-thumb {
    background: rgba(160,160,160, 0.2);
    border-radius: 5px;
    border: 1px solid rgba(88,88,88,0.2);
}

.schedule_days:hover::-webkit-scrollbar-thumb {
    background: rgba(220,220,220, 0.9);
}

.schedule_days div {
    display: inline-block;
    width: 100px!important;
    height: 100px;
    text-align: center;
    white-space: normal;
    border: none;
}

.schedule_days div:not(:first-of-type) {
    border-left: #FFF 2px solid;
}

.schedule_days div a:link, .schedule_days div a:visited {
    padding: 9px 0px 1px 0px;
    font-size: 24px;
    font-weight: bold;
    color: #FFF;
    text-decoration: none;
    text-transform: lowercase;
    display: block;
    width: 100%;
    height: 100%;
}

.schedule_days div a:hover {
    transition-duration: 0.25s;
    background-color: rgb(174,14,22);
}

.schedule_days .expired {
    background-color: rgb(174,14,22);
}

.schedule_days .selected {
    background-color: rgb(274,150,150)!important;
    color: rgb(174,14,22);
}

.schedule_days .today {
    background-color: rgb(274,200,200);
    color: rgb(174,14,22);
}

.schedule_days .selected a:link, .schedule_days .selected a:visited {
    color: rgb(174,14,22);
}

.schedule_days .selected a:hover {
    transition-duration: 0.25s;
    color: #FFF;
    background-color: #888888;
}

.schedule_days div a:hover {
    transition-duration: 0.25s;
    background-color: rgb(174,14,22);
}

.schedule-day {
    margin-top: 10px;
}

.schedule_grid {
    display: grid!important;
    row-gap: 20px;
}

.schedule_grid .item {
    text-align:center;
    position: relative;
    border: #000 2px solid;
}

.schedule_picture {
    width: 100%;
}

.schedule_info {
    background-color: #000;
    color: #FFF;
    height: 61px!important;
}

.schedule_grid .item a:link, .schedule_grid .item a:visited {
    text-decoration: none;
}

.schedule_info a:link, .schedule_info a:visited {
    color: #FFF;
    font-weight: bold;
}

.schedule_info .schedule_time {
    color: #000;
    background-color: #FFF;
    font-weight: bold;
    padding-top: 7px;
}

.schedule_info * {
    padding: 0px;
    margin: 0px;
}

.item .preview {
    color: #FFF;
    text-align: left!important;
    text-decoration: none;
    font-family: brothers, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    position: absolute;
    top: 10px;
    left: 10px;
    text-shadow: 1px 1px 2px rgb(174,14,22);
    text-transform: uppercase;
}

.item:hover .preview {
    transition-duration: 0.5s;
    color: rgb(174,14,22);
    text-shadow: none;
}

.details_screenings a:link, .details_screenings a:visited {
    margin-top: 20px;
    padding: 10px 40px 10px 40px;
    background-color: #000;
    border-radius: 20px;
    text-decoration: none;
    color: #FFF;
    font-weight: bold;
}

.details_screenings a:hover {
    transition-duration: 0.5s;
    color: rgb(174,14,22);
}

.guest {
    position: relative;
}

.guest .info-guest {
    color: #FFF;
    background-color: #000!important;
    font-family: brothers, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
}
.guest-name {
    font-size: 24px!important;
}

copy {
    color: #FFF;
    font-family: brothers, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    text-shadow: 1px 1px 2px rgb(0,0,0);
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    transform: rotate(-90deg);
    transform-origin: right;
}

.transparent {
    opacity: 0;
}

.expired a:link, .expired a:visited {
    background-color: rgba(174, 14, 22, 0.5);
    /*background-color: rgba(220, 220, 220, 0.8);*/
}

.expired a:hover {
    /*background-color: rgba(174, 14, 22, 0.5);*/
    background-color: rgba(220, 220, 220, 0.8);
}

.home-ticketing {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 16vw;
    bottom: 0px;
    left: 0px;
    right: 0px;
    vertical-align: central;
    text-align:center;
}

.home-ticketing .title {
    font-size: 45px!important;
    line-height: 50px;
    color: #FFF!important;
    text-shadow: 2px 2px 3px rgb(40,40,40);
}

.home-ticketing {
    padding-bottom: 40px;
}

.home-ticketing a:link, .home-ticketing a:visited {
    background-color: rgb(174,14,22);
    padding: 15px 30px 15px 30px;
    font-weight: bold;
    font-family: work-sans, sans-serif;
    color: #FFF;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
}

.home-ticketing a:hover {
    background-color: #FFF;
    transition-duration: 0.5s;
    color: rgb(174,14,22);
}

.home_schedule_title {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #FFF;
    text-transform: uppercase;
    text-align: right;
    text-shadow: 1px 1px 2px rgb(174,14,22);
}

@media all and (max-width: 576px) {
    #general-navigation {
        grid-template-columns: auto 80px;
    }

    #general-navigation-logo-small {
        max-height: 40px;
        margin: 5px;
    }

    #general-navigation-logo-small img {
        max-width: 100%;
        max-height: 100%;
    }

    #menu-modal-open {
        margin-right: 10px;
        padding: 5px;
        text-align: right;
    }

    #menu-flip {
        display: none;
        background-color: #000;
        text-align: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 99999;
        -webkit-transition: display 100ms ease-in;
        -moz-transition: display 100ms ease-in;
        transition: display 100ms ease-in;
        pointer-events: none;
    }

    #menu-flip:target {
        pointer-events: auto;
        display: block;
    }

    #menu-modal ul {
        padding: 10%;
        width: 100%;
    }

    #menu-modal li {
        text-align: left;
        line-height: 45px;
    }

    .logo-menu {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        text-align: center;
        width: 70%;
    }

    #menu-close {
        font-family: Arial, Helvetica, sans-serif!important;
        display: block;
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 50px!important;
    }

    #menu-alter img {
        max-width: 60px;
    }

    #footer {
        text-align: center;
    }

    #footer-links {
    }

    .edito-grid {
        text-align: center;
    }

    .content-logo, .content-nullable {
        display:none;
    }

    .item-grid {
        grid-template-columns: auto;
    }

    .carousel {
        display: none;
    }

    .information-grid {
        grid-template-columns: 100%;
    }
}

@media all and (min-width: 576px) {
    #general-navigation {
        text-align: center;
    }

    #general-navigation-logo-small {
        display: none;
    }

    #menu-modal ul {
        margin-bottom: 0px;
    }

    #menu-modal li {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 40px;
    }

    #menu-modal-open {
        display: none;
    }

    #menu-alter {
        display: inline-grid;
    }

    #menu-alter img {
        max-width: 40px;
    }

    #menu-flip {
        display: block;
    }

    #menu-flip .logo-menu, #menu-flip .social-network {
        display: none;
    }

    #menu-close {
        display: none;
    }

    #footer-links {
    }

    .content-logo, .content-nullable {
        display:none;
    }

    .edito-grid {
        text-align: center;
    }

    .home_schedule_title {
        font-size: 18px;
    }
}

@media all and (min-width: 768px) {

    #footer-links {
    }

    .content-canvas {
        display:grid;
        grid-template-columns: auto 700px auto;
    }

    .content-logo, .content-nullable {
        display: inline-grid;
        text-align: right;
    }

    .content-logo img {
        width: 90%;
        max-width: 190px;
        padding-right: 20px;
    }

    .edito-grid {
        text-align: left;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .item-grid {
        grid-template-columns: 50% 50%;
    }

    .schedule_grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5%;
    }
}

@media all and (min-width: 992px) {

    #footer-links {
    }

    .content-canvas {
        display:grid;
        grid-template-columns: auto 870px auto;
    }

    .home_schedule_title {
        font-size: 26px;
    }
}

@media all and (min-width: 1200px) {
    .content-canvas {
        display:grid;
        grid-template-columns: auto 1080px auto;
    }

    .item-grid {
        grid-template-columns: 33% 33% 33%;
    }

    .schedule_grid {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5%;
    }
}

@media all and (min-width: 1400px) {
    .content-canvas {
        display:grid;
        grid-template-columns: auto 1280px auto;
    }
}

.carousel-palmares {
    background-color: #ccff99;
}

.expired-edition {
    display:block;
    text-align: center;
    font-family: brothers, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    color: #FFF;
    background-color: rgb(174,14,22);
    padding: 10px;
}

.expired-edition a:link, .expired-edition a:visited {
    color: #ffff00 !important;
    text-decoration: none;
}

.expired-edition a:hover {
    text-decoration: underline;
}